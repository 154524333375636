<template>
  <div>
    <h3>The Instruction for each test will be displayed in this route</h3>
    <div>
      <button type="button" @click="handleStartTest()" :disabled="loading">
        Start Now
        <b-spinner small v-if="loading"></b-spinner>
      </button>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('staging/test');
export default {
  name: 'TestInstrucion',
  data() {
    return {
      bookId: this.$route.params.book_id,
      quizCount: '3',
      timeAllowed: '90',

      loading: false,
    };
  },
  methods: {
    ...mapActions(['createTest']),
    handleStartTest() {
      const { bookId, quizCount, timeAllowed } = this;
      const testDetails = {
        book_id: bookId,
        quiz_count: +quizCount,
        time_allowed: +timeAllowed,
      };
      this.loading = true;
      this.createTest(JSON.stringify(testDetails))
        .then(() => {
          if (this.test_id) {
            this.loading = false;
            this.$router.push({ name: 'TakeTest', params: { book_id: this.bookId, test_id: this.test_id } });
          }
        })
        .catch((err) => {
          this.loading = false;
          const errInfo = err.data.message;
          this.$toast.error(errInfo, 'Error');
        });
    },
  },
  computed: {
    ...mapGetters(['test_id']),
  },
};
</script>

<style>

</style>
